<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-base py-7 mb--100">
      <div class="container">
        <notifications></notifications>
      </div>
      <div class="header-body text-center mb-7">
        <div class="row justify-content-center">
          <div class="text-center mb-4" style="margin-bottom: 0px">
            <!-- <h1 class="text-white">
                Get started immediately!
              </h1>
              <p class="text-lead text-white">
                As humans we tend to forget things time-to-time.
                <br> All fine, we got your back, just request a new login Password
              </p> -->
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--9 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>{{$t("email.resetPassword")}}</small>
              </div>

              <form class="needs-validation" @submit.prevent="handleSubmit()">
                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="ni ni-lock-circle-open"
                  :placeholder="$t('email.password')"
                  type="password"
                  name="Password"
                  v-model="form.data.attributes.password"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.password" />

                <base-input
                  alternative
                  class="mb-3"
                  prepend-icon="ni ni-lock-circle-open"
                  :placeholder="$t('email.confirmPassword')"
                  type="password"
                  name="Password confirmation"
                  v-model="form.data.attributes.password_confirmation"
                >
                </base-input>

                <div class="text-center">
                  <base-button type="primary" native-type="submit" class="my-4"
                    >{{$t("email.resetPassword")}}</base-button
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import { Select, Option } from "element-ui";
export default {
  layout: "AuthLayout",
  mixins: [formMixin],
  components: { 
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    },
  data() {
    return {
       langs:["en","el"],
      form: {
        data: {
          type: "password-reset",
          attributes: {
            password: "",
            password_confirmation: "",
            token: "",
            email: "",
          },
        },
      },
    };
  },
  mounted() {
    this.form.data.attributes.email = this.$route.query.email;
    this.form.data.attributes.token = this.$route.query.token;
  },
  beforeDestroy() {
    // Check if the current route already has query parameters
    const hasQueryParameters = Object.keys(this.$route.query).length > 0;

    // If there are query parameters, navigate to the same route with null query
    if (hasQueryParameters) {
      this.$router.replace({ query: null });
    }
  },
  methods: {
    getLanguageLabel(lang) {
          return lang[0].toUpperCase() + lang.slice(1);
        },
    async handleSubmit() {
      try {
        await this.$store
          .dispatch("reset/createNewPassword", this.form.data)
          .then(() => {
            this.$router.push({ name: "Login" });
          });
      } catch (error) {
        await this.$notify({
          type: "danger",
          message:
           this.$t('email.TheGivenEmailWasInvalidPleaseContactTheSupportTeam')+'.',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
<style>
.language-selector {
  top: -75px;
  right: -600px;
  z-index:1000;
}
</style>