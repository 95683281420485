<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-base py-7 mb--100">
      <div class="container">
        <notifications></notifications>
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="text-center mb-4" style="margin-bottom: 0px">
              <h1 class="text-white">
               {{$t("reset.getStartedImmediately")}}!
              </h1>
              <p class="text-lead text-white">
                {{$t("reset.asHumansWeTendToForgerThingsTimeToTime")}}.
                <br> {{$t("reset.allFineWeGotYourBackJustRequestNewLoginPassword")}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Page content -->
    <div class="container mt--9 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>{{$t("reset.resetPassword")}}</small>
              </div>

              <form class="needs-validation" @submit.prevent="handleSubmit()">
                <base-input
                  class="mb-3"
                  name="Email"
                  prepend-icon="ni ni-email-83"
                  placeholder="Email"
                  v-model="form.data.attributes.email"
                  required
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.email" />

                <div class="text-center">
                  <!-- <base-button type="submit" @click.prevent="handleSubmit" class="my-4">Sign in</base-button> -->
                  <base-button type="primary" native-type="submit" class="my-4"
                    >{{$t("reset.sendPasswordResetLink")}}</base-button
                  >
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6"></div>
            <div class="col-6  text-right">
              <a href="/login" class="text-light"
                ><small>
                  <i class="fa fa-arrow-left"/> {{$t("reset.backToLogin")}}
                </small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import { Select, Option } from "element-ui";
export default {
  layout: "AuthLayout",
  mixins: [formMixin],
  components: { 
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
    },
  data() {
    return {
        langs:["en","el"],
      form: {
        data: {
          type: "password-forgot",
          attributes: {
            email: "",
            redirect_url: process.env.VUE_APP_BASE_URL + "/password/email",
          
          },
        },
      },
    };
  },
  methods: {
    getLanguageLabel(lang) {
          return lang[0].toUpperCase() + lang.slice(1);
        },
    async handleSubmit() {
      if (process.env.VUE_APP_IS_DEMO == 1) {
        await this.$notify({
          type: "danger",
          message:this.$t('reset.passwordResetIsDisabledInTheDemo')+'.',
        });
        return;
      }
      try {
        await this.$store.dispatch("reset/forgotPassword", this.form.data);
        await this.$notify({
          type: "success",
          message: this.$t('reset.anEmailWithResetPasswordLinkWasSent'),
        });
      } catch (error) {
        await this.$notify({
          type: "danger",
          message:this.$t('reset.weCantFindUserWithThatEmailAddress')+'.',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
<style>
.language-selector {
  top: -75px;
  right: -600px;
  z-index:1000;
}
</style>
